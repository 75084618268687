import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import HomeContent from './HomeContent';
import TeamContent from './TeamContent';
import NavigationBar from './NavigationBar';
import translations from '../utils/translations';

const StartPageContent = () => {
  const { language } = useContext(AppContext);
  return (
    <>
      <div className="relative flex flex-col w-full h-screen text-center bg-[url('/public/media/oc_home.jpg')] bg-center bg-cover opacity-95">
        <NavigationBar />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-full px-4 md:w-3/4 lg:w-3/4 text-5xl md:text-6xl lg:text-7xl text-white font-crimson overflow-hidden">
          {translations[language].homeMotto}
        </div>
      </div>

      <HomeContent id="homesection" />
      <TeamContent id="teamsection" />
    </>
  );
};

export default StartPageContent;
