import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import StartPageContent from '../components/StartPageContent';
import TeamMemberPage from '../components/TeamMemberPage';
import ListingsContent from '../components/listings/ListingsContent';
import ContactUs from '../components/ContactUs';
import Scroller from '../components/Scroller';

const AppRoutes = () => {
  const location = useLocation();

  return (
    <>
      <Scroller />
      <Routes>
        <Route exact path="/" element={<StartPageContent />} />
        <Route exact path="/listings" element={<ListingsContent />} />
        <Route path="/team/:nickname" element={<TeamMemberPage />} />
      </Routes>
      {location.pathname !== '/listings' && <ContactUs id="contactussection" />}
    </>
  );
};

export default AppRoutes;
