import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { useParams } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import { membersByNickname } from '../utils/members';
import translations from '../utils/translations';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { ENGLISH } from '../utils/constants';

const TeamMemberPage = () => {
  const { language } = useContext(AppContext);
  const { nickname } = useParams();
  const member = membersByNickname[nickname];
  const name = language === ENGLISH ? member.name : member.nameCn;
  const role = language === ENGLISH ? member.role : member.roleCn;
  const bio = language === ENGLISH ? member.bio : member.bio;

  return (
    <div>
      <NavigationBar />
      <div className="py-12 sm:py-16 md:py-24 px-4 md:px-12 lg:px-36">
        <h3 className="text-3xl md:text-4xl lg:text-5xl font-crimson tracking-tight flex shrink-0">
          {name}
        </h3>
        <hr className="my-4 border-t-2 border-gray-300" />
        <div className="flex flex-col md:flex-row">
          <div className="md:w-2/10 mb-4 md:mb-0">
            <img
              className="object-cover member-page-photo w-1/2 mx-auto md:w-full"
              src={`/media/portraits/${nickname}.jpg`}
              alt={name}
              onError={(e) => {
                e.target.style.display = 'none';
              }}
            />
          </div>
          <div className="md:w-5/10 md:pl-10">
            <h3 className="text-xl md:text-2xl font-crimson tracking-tight">{role}</h3>
            <div
              style={{ whiteSpace: 'pre-line' }}
              className="pt-2 text-base md:text-lg font-crimson max-w-lg"
            >
              {bio}
            </div>
          </div>
          <div className="text-base md:text-lg font-crimson mt-6 md:mt-0 md:pl-10 lg:pl-20 md:w-2/10">
            <div class="flex flex-row">
              <PhoneIcon
                className="h-5 w-5 inline mr-1 flex shrink-0"
                aria-hidden="true"
              />
              {member.phone}
            </div>
            <div class="flex flex-row">
              <EnvelopeIcon
                className="h-5 w-5 inline mr-1 flex shrink-0"
                aria-hidden="true"
              />
              <a href={`mailto:${member.email}`}>{member.email}</a>
            </div>
            <div class="flex flex-row">
              {translations[language].license} # {member.brokerLicNumber}
            </div>
            <div class="flex flex-row">
              {member.realtorDRE && <span>DRE # {member.realtorDRE}</span>}
            </div>
            {member.wechat && (
              <div class="flex flex-row">
                <img
                  src="/media/logos-social/wechat.svg"
                  class="w-7 h-7 mr-2"
                  alt="WeChat QR Code"
                />
                {member.wechat}
              </div>
            )}
            <div class="flex flex-row pt-4">
              {member.zillow && (
                <a
                  href={member.zillow}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/media/logos-social/zillow.svg"
                    class="w-8 h-8 mr-2"
                    alt="Zillow"
                  />
                </a>
              )}
              {member.linkedin && (
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/media/logos-social/linkedin.svg"
                    class="w-8 h-8 mr-2"
                    alt="Linkedin"
                  />
                </a>
              )}
              {member.instagram && (
                <a
                  href={member.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/media/logos-social/instagram.svg"
                    class="w-8 h-8 mr-2"
                    alt="Instagram"
                  />
                </a>
              )}
              {member.xiaohongshu && (
                <a
                  href={member.xiaohongshu}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/media/logos-social/xiaohongshu.svg"
                    class="w-8 h-8 mr-2"
                    alt="xiaohongshu"
                  />
                </a>
              )}
            </div>
            <div class="flex flex-row pt-4 wechat-photo">
              <img
                id={`wechat_${nickname}`}
                src={`/media/wechat-qr/${nickname}.jpg`}
                alt={name}
                onError={(e) => {
                  e.target.style.display = 'none';
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberPage;
