import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './utils/routes';
import { QueryClient, QueryClientProvider } from 'react-query';

// default global query client that caches for 24 hours
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 24 * 60 * 60 * 1000,
    },
  },
});

export default function Main() {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <body class="flex flex-col">
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </body>
      </div>
    </QueryClientProvider>
  );
}
