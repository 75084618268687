import React, { useState, useEffect } from 'react';
import { ChevronDoubleUpIcon } from '@heroicons/react/24/outline';

// Implement scroll to top button
const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <button
      className={`scroll-to-top w-14 h-14 z-6 justify-center items-center rounded-full ${
        isVisible ? 'flex' : 'hidden'
      }`}
      onClick={scrollToTop}
    >
      <ChevronDoubleUpIcon className="h-16 w-16" />
    </button>
  );
};

export default ScrollToTop;
