import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import translations from '../utils/translations';
import { useLocation } from 'react-router-dom';

const ContactUs = () => {
  const { language } = useContext(AppContext);
  const { pathname } = useLocation();

  return (
    <div id="contactussection" className="team-content font-crimson">
      {pathname !== '/' && <hr className="border-t-2 border-gray-300 m-4" />}
      {pathname === '/' && (
        <div className="flex flex-col lg:flex-row h-96 items-center justify-center text-center bg-[url('/public/media/oc_contact.jpg')] bg-center bg-cover">
          <span className="text-4xl md:text-5xl lg:text-7xl text-white lg:w-1/2 mx-4 lg:mx-20 my-4 font-crimson">
            {translations[language].workwithus}
          </span>
          <span className="text-xl md:text-2xl lg:text-3xl text-white lg:w-1/2 mx-6 lg:mx-20 lg:mx-20">
            {translations[language].workwithusblurb}
          </span>
        </div>
      )}

      <div className="grid lg:grid-cols-2 pt-10 px-10">
        <div className="p-4 text-grey text-4xl text-slate-800 font-crimson lg:row-span-1 lg:col-span-2">
          {translations[language].getintouch}
        </div>

        <div className="font-jost lg:pr-16">
          <p className="p-4 text-slate-600">
            {translations[language].reachout}
          </p>
          <div className="flex flex-col lg:flex-row">
            <div className="p-4 w-full lg:w-1/2">
              <span className="font-semibold">
                {translations[language].address}
              </span>
              <div>830 Roosevelt Suite</div>
              <div>#230</div>
              <div>Irvine CA 92620</div>
            </div>
            <div className="p-4 w-full lg:w-1/2">
              <span className="font-semibold">
                {translations[language].phonenumber}
              </span>
              <div>949.870.9756</div>
              <br />
              <span className="font-semibold">
                {translations[language].email}
              </span>
              <div>
                <a href={`mailto:alinkrealty@gmail.com`}>
                  alinkrealty@gmail.com
                </a>
              </div>

              <a
                href="https://www.linkedin.com/company/alinkrealestate/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/logos-social/linkedin.svg"
                  class="w-10 h-10 mr-2 mt-5"
                  alt="Linkedin"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
