import React, { useState } from 'react';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import { capitalizeWords } from '../../utils/utils';
import { addToFirebase } from '../../firebase';

const AddListingModal = ({ isOpen, closeModal, onSubmit }) => {
  const onRequestClose = () => {
    closeModal();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      Address: '',
      Agent: '',
      City: '',
      ClosedDate: null,
      ClosingPrice: null,
      ImageUrl: '',
      ListingPrice: null,
      NumBath: null,
      NumBed: null,
      Represented: null,
      SqFt: null,
      State: 'For Sale',
      ZillowLink: null,
      ZipCode: null,
    },
    onSubmit: (values) => {
      onSubmit(values);
      formik.resetForm();
      onRequestClose();
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: '60%', // Set the width to 75% of the screen
          margin: 'auto', // Center the modal horizontally
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-2">
          <div className="border-b border-gray-900/10 pb-2">
            <p className="text-base font-semibold leading-7 text-gray-900">
              Add a new listing
            </p>
          </div>
          <div className="border-b border-gray-900/10 pb-6">
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="Address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  House / Street Address
                </label>
                <div className="mt-2">
                  <input
                    id="Address"
                    name="Address"
                    type="text"
                    required
                    onChange={(event) => {
                      formik.setFieldValue(
                        'Address',
                        capitalizeWords(event.target.value),
                      );
                    }}
                    value={formik.values.Address}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="City"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    id="City"
                    name="City"
                    type="text"
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      formik.setFieldValue('City', capitalizeWords(value));
                    }}
                    value={formik.values.City}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="ZipCode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Zip code
                </label>
                <div className="mt-2">
                  <input
                    id="ZipCode"
                    name="ZipCode"
                    type="text"
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      formik.setFieldValue(
                        'ZipCode',
                        value.replace(/[^0-9]/g, ''),
                      );
                    }}
                    value={formik.values.ZipCode}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="ListingPrice"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Listing Price ($)
                </label>
                <div className="mt-2">
                  <input
                    id="ListingPrice"
                    name="ListingPrice"
                    type="text"
                    onChange={(event) => {
                      const value = event.target.value;
                      formik.setFieldValue(
                        'ListingPrice',
                        value.replace(/[^0-9]/g, ''),
                      );
                    }}
                    value={formik.values.ListingPrice}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="NumBed"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  # Bed
                </label>
                <div className="mt-2">
                  <input
                    id="NumBed"
                    name="NumBed"
                    type="text"
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      // Allow only numbers with up to one decimal place
                      if (/^\d*\.?\d{0,1}$/.test(value)) {
                        formik.setFieldValue('NumBed', value);
                      }
                    }}
                    value={formik.values.NumBed}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="NumBath"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  # Bath
                </label>
                <div className="mt-2">
                  <input
                    id="NumBath"
                    name="NumBath"
                    type="text"
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      // Allow only numbers with up to one decimal place
                      if (/^\d*\.?\d{0,1}$/.test(value)) {
                        formik.setFieldValue('NumBath', value);
                      }
                    }}
                    value={formik.values.NumBath}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="SqFt"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  # Sq Ft
                </label>
                <div className="mt-2">
                  <input
                    id="SqFt"
                    name="SqFt"
                    type="text"
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      formik.setFieldValue(
                        'SqFt',
                        value.replace(/[^0-9]/g, ''),
                      );
                    }}
                    value={formik.values.SqFt}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="ImageUrl"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Image Link
                </label>
                <div className="mt-2">
                  <input
                    id="ImageUrl"
                    name="ImageUrl"
                    type="text"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.ImageUrl}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="ZillowLink"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Zillow Link
                </label>
                <div className="mt-2">
                  <input
                    id="ZillowLink"
                    name="ZillowLink"
                    type="text"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.ZillowLink}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="Agent"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Agent Name
                </label>
                <div className="mt-2">
                  <input
                    id="Agent"
                    name="Agent"
                    type="text"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.Agent}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="State"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Listing status
                </label>
                <div className="mt-2">
                  <select
                    id="State"
                    name="State"
                    onChange={formik.handleChange}
                    value={formik.values.State}
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="" disabled>
                      Select an Option
                    </option>
                    <option value="Closed">Closed</option>
                    <option value="For Sale">For Sale</option>
                    <option value="For Rent">For Rent</option>
                    <option value="Active Under Contract">Active Under Contract</option>
                    <option value="Coming Soon">Coming Soon</option>
                  </select>
                </div>
              </div>

              {formik.values.State === 'Closed' && (
                <>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="ClosingPrice"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Closing Price ($)
                    </label>
                    <div className="mt-2">
                      <input
                        id="ClosingPrice"
                        name="ClosingPrice"
                        type="text"
                        onChange={(event) => {
                          const value = event.target.value;
                          formik.setFieldValue(
                            'ClosingPrice',
                            value.replace(/[^0-9]/g, ''),
                          );
                        }}
                        value={formik.values.ClosingPrice}
                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="ClosingDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Closed Date
                    </label>
                    <DatePicker
                      id="ClosedDate"
                      name="ClosedDate"
                      selected={formik.values.ClosedDate}
                      onChange={(date) =>
                        formik.setFieldValue('ClosedDate', date)
                      }
                      popperPlacement="right"
                      dateFormat="MM-DD-YYYY"
                      placeholderText="Select a Date"
                      className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="Represented"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Represented
                    </label>
                    <div className="mt-2">
                      <select
                        id="Represented"
                        name="Represented"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.Represented}
                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value="" disabled>
                          Select an Option
                        </option>
                        <option value="None">N/A</option>
                        <option value="Buyer">Buyer</option>
                        <option value="Seller">Seller</option>
                        <option value="Both">Both</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            onClick={onRequestClose}
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

const AddListingButton = ({ refetch }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = (values) => {
    addToFirebase(values);
    refetch();
    closeModal();
  };

  return (
    <div>
      <button
        onClick={openModal}
        className="addListingButton bg-regal-blue text-white px-4 py-2 rounded hover:bg-opacity-90"
      >
        +
      </button>
      <AddListingModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default AddListingButton;
