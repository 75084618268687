import React from 'react';

const homePageDescriptionEN =
  'With 45 years of comprehensive experience, we have deep roots in Southern California, witnessed the growth of Orange County, and are committed to bringing you the most perfect home buying experience. \n ' +
  'The ALINK team has the most in-depth understanding of the real estate market in Orange County cities including Irvine, Newport Beach, Arcadia, Pasadena, Lake Forest, Yorba Linda, Fullerton, and more! \n' +
  'Every transaction of the team is based on accurate analysis of market information, and we use the most professional attitude to protect your real estate transaction. Our service details and commitments are not only reflected in all aspects of real estate transactions, ' +
  'but also provide you with advice in various fields such as asset allocation, wealth management, and education planning. Our one-stop settlement service allows you to have a good integration experience in your new community. \n' +
  'ALINK treats every customer as a lifelong family member and friend, using our professionalism and focus to ensure your peace of mind. \n ALINK invites you to explore the beauty of Orange County!';

const homePageDescriptionCN =
  '45年综合经验，深耕南加，见证橙县成长，致力于为您带来最完美的购房体验。\n ALINK团队对包括尔湾，新港，阿卡迪亚，帕萨迪纳，森林湖，约巴林达等橙县各城市的房产市场有着最深入的了解。实时掌握房市信息，精准把握市场动态。\n ' +
  '团队的每一笔交易都以对市场信息精准分析为基础，用最专业的态度为您的房产交易保驾护航。\n' +
  '我们服务细节和承诺不仅体现在房产交易的各个环节上，还在资产配置，财富管理，教育规划等各个领域为您提供建议，一站式安家服务，让您在新的社区获得良好的融入体验。\n' +
  'ALINK把每一位客户都视为一生的家人和朋友，用我们的专业和专注，确保您的安心和放心。\n' +
  'ALINK邀您一起探索橙县之美！';

const workWithUsEN =
  "Buying or selling a home is a significant milestone. Whether you're starting a new chapter, or moving onto another one, we will be there at every step to guide you with our expertise and dedication.";
const workWithUsCN =
  '购买或出售房屋是一个重要的里程碑。无论您是开始新的篇章，还是进入下一个环节，我们都将在每一步为您提供基于专业知识的具体指导。';

const getInTouchEN =
  "If you are curious to hear more about us and what we can offer, don't hesitate to get in touch. Connect with our real estate team today to begin the journey.";
const getInTouchCN =
  '如果您想更多地了解我们以及我们所能提供的各项服务，请随时与我们联系。立即与我们的房地产团队联系，开始旅程.';

const translations = {
  en: {
    aboutus: 'ABOUT US',
    address: 'Address',
    email: 'Email',
    contact: 'Contact',
    contactus: 'Contact Us',
    getintouch: 'Get In Touch',
    home: 'Home',
    homeMotto: 'Inspired living in Orange County starts with the perfect home.',
    homePageDescription: convertNewLineToBreak(homePageDescriptionEN),
    houseSales: 'Home sales',
    keyStatistics: 'Our Key Statistics:',
    license: 'License',
    listings: 'Listings',
    name: 'Your Name',
    pastTwoYearSales: 'Sales in 2022 and 2023',
    phonenumber: 'Phone Number',
    managedProperties: 'Managed properties',
    no1salesteam: 'The #1 Sales Team in Orange County!',
    ourteam: 'Our Team',
    reachout: getInTouchEN,
    sendmessage: 'Send us a message',
    subject: 'Subject',
    yourmessage: 'Your Message',
    workwithus: 'Work With Us',
    workwithusblurb: workWithUsEN,
    whyus: 'Why Alink?',
  },
  cn: {
    aboutus: '关于我们',
    address: '地址',
    email: '电子邮件',
    contact: '联络我们',
    contactus: '联络我们',
    getintouch: '保持联系',
    home: '主页',
    homeMotto: '梦想之家，始于ALINK.',
    homePageDescription: convertNewLineToBreak(homePageDescriptionCN),
    houseSales: '房屋交易',
    license: '执照',
    name: '姓名',
    keyStatistics: '我们拥有:',
    pastTwoYearSales: '2亿美金销售额 (2022-2023)',
    phonenumber: '电话',
    listings: 'Listings',
    managedProperties: '出租管理',
    no1salesteam: 'OC排名第一的地产销售团队!',
    ourteam: '我们的团队',
    reachout: getInTouchCN,
    sendmessage: '给我们发信息',
    subject: '标题',
    yourmessage: '您的留言',
    workwithus: '与我们合作，同行',
    workwithusblurb: workWithUsCN,
    whyus: '为什么选择嘉联?',
  },
};

function convertNewLine(str) {
  return str.split('\n').map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));
}

function convertNewLineToBreak(str) {
  return str.split('\n').map((text, index) => (
    <React.Fragment key={index}>
      <p> {text} </p>
      <br />
    </React.Fragment>
  ));
}

export default translations;
