import { Timestamp } from 'firebase/firestore';

export function formatDate(date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return year + '/' + month + '/' + day;
}

export function capitalizeWords(str) {
  return str.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export function convertToDate(date) {
  if (date instanceof Timestamp) {
    return date.toDate();
  } else {
    return new Date(date); // or return date if it's already a JavaScript Date object
  }
}