import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ALinkHeaderInfo = () => {
  const navigate = useNavigate();

  return (
    <a
      style={{ cursor: 'pointer' }}
      onClick={() => {
        navigate('/');
      }}
    >
      <div className="flex items-center px-4 pb-4">
        <span className="sr-only"> ALink Realty Group </span>
        <img
          src="/media/logos-alink/alink_main_logo.png"
          alt="Not Found"
          className="w-auto h-12 md:h-20"
        />
      </div>
    </a>
  );
};

export default ALinkHeaderInfo;
