import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import translations from '../utils/translations';

const HomeContent = () => {
  const { language } = useContext(AppContext);
  return (
    <div id="homesection" className="home-content">
      <div className="items-center flex flex-col">
        <div className="flex flex-col md:flex-row px-8 md:px-16 py-12 md:py-24 text-xl md:text-2xl font-crimson">
          <div className="flex flex-col w-full md:w-2/3 px-4 md:px-20">
            <span className="text-3xl md:text-5xl font-crimson pb-6">
              {translations[language].no1salesteam}
            </span>
            <p className="text-base md:text-xl">
              {translations[language].homePageDescription}
            </p>
          </div>
          <div className="flex flex-col items-center md:items-start md:pl-20 mt-6 md:mt-0">
            <div className="mb-4 text-center md:text-left">
              <div className="text-yellow-600 text-3xl md:text-5xl font-bold mb-2">
                1000+
              </div>
              <div className="text-base md:text-xl">
                {translations[language].houseSales}
              </div>
            </div>
            <div className="mb-4 text-center md:text-left">
              <div className="text-yellow-600 text-3xl md:text-5xl font-bold mb-2">
                150+
              </div>
              <div className="text-base md:text-xl">
                {translations[language].managedProperties}
              </div>
            </div>
            <div className="mb-4 text-center md:text-left">
              <div className="text-yellow-600 text-3xl md:text-5xl font-bold mb-2">
                $200 million+
              </div>
              <div className="text-base md:text-xl">
                {translations[language].pastTwoYearSales}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
