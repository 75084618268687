import React, { useEffect, createContext, useState } from 'react';
import { ENGLISH } from './utils/constants';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const auth = getAuth();
  const [language, setLanguage] = useState(ENGLISH);
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

  const contextValue = {
    language,
    setLanguage,
    userIsAuthenticated,
    setUserIsAuthenticated,
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserIsAuthenticated(!!user); // Set userIsAuthenticated based on whether a user exists
    });

    // Unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, [auth]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppProvider };
