import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  doc,
  collection,
  setDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAm2HNed5nKBVjiw5w6-izcQUT-_znDOCQ',
  authDomain: 'alink-real-estate.firebaseapp.com',
  databaseURL: 'https://alink-real-estate-default-rtdb.firebaseio.com',
  projectId: 'alink-real-estate',
  storageBucket: 'alink-real-estate.appspot.com',
  messagingSenderId: '246723556121',
  appId: '1:246723556121:web:3d1446d2e49b62fe718b88',
};

const firebaseApp = initializeApp(firebaseConfig);
const fireauth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

async function addToFirebase(data) {
  try {
    await addDoc(collection(firestore, 'listings'), {
      Address: data.Address,
      Agent: data.Agent,
      City: data.City,
      ClosedDate: data.ClosedDate === undefined ? null : data.ClosedDate,
      ClosingPrice: data.ClosingPrice === undefined ? null : data.ClosingPrice,
      ImageUrl: data.ImageUrl,
      ListingPrice: data.ListingPrice,
      NumBath: data.NumBath,
      NumBed: data.NumBed,
      Represented: data.Represented === undefined ? null : data.Represented,
      SqFt: data.SqFt,
      State: data.State,
      ZillowLink: data.ZillowLink,
      ZipCode: data.ZipCode,
    });

    console.log('Added document with address: ', data.Address);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function upsertToFirebase(id, data) {
  try {
    const docRef = doc(collection(firestore, 'listings'), id);
    await setDoc(
      docRef,
      {
        Address: data.Address,
        Agent: data.Agent,
        City: data.City,
        ClosedDate: data.ClosedDate
          ? new Date(data.ClosedDate).toISOString()
          : null,
        ClosingPrice: data.ClosingPrice ? Number(data.ClosingPrice) : null,
        ImageUrl: data.ImageUrl,
        ListingPrice: data.ListingPrice ? Number(data.ListingPrice) : null,
        NumBath: data.NumBath ? Number(data.NumBath) : null,
        NumBed: data.NumBed ? Number(data.NumBed) : null,
        Represented: data.Represented,
        SqFt: data.SqFt ? Number(data.SqFt) : null,
        State: data.State,
        ZillowLink: data.ZillowLink,
        ZipCode: data.ZipCode,
      },
      { merge: true },
    ); // merge: true will update fields in the document or create it if it doesn't exist

    console.log('Upserted document with id: ', id);
  } catch (e) {
    console.error('Error upserting document: ', e);
  }
}

async function deleteFromFirebase(id) {
  try {
    const docRef = doc(firestore, 'listings', id);
    await deleteDoc(docRef);
    console.log('Deleted document with id: ', id);
  } catch (e) {
    console.error('Error deleting document: ', e);
  }
}

export {
  fireauth,
  firestore,
  addToFirebase,
  deleteFromFirebase,
  upsertToFirebase,
};
