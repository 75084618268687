import React, { useContext } from 'react';
import { ENGLISH, CHINESE } from '../utils/constants';
import { AppContext } from '../AppContext';
import LoginPopover from './LoginPopover';

const LanguageSelect = () => {
  const { setLanguage } = useContext(AppContext);
  return (
    <div className="lang-select-container">
      <button className="lang-select-btn" onClick={() => setLanguage(ENGLISH)}>
        <span className="text-sm sm:text-lg font-crimson text-base font-medium">
          Eng
        </span>
      </button>

      <button className="lang-select-btn" onClick={() => setLanguage(CHINESE)}>
        <span className="text-sm sm:text-md font-crimson text-base font-medium">
          中文
        </span>
      </button>

      {/* <LoginPopover /> */}
    </div>
  );
};

export default LanguageSelect;
