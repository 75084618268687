import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import translations from '../utils/translations';
import { coFounders, teamMembers } from '../utils/members';
import { ENGLISH } from '../utils/constants';

// Returns card for one team member
function TeamMember({ member, language, index }) {
  const navigate = useNavigate();

  const { nickname, bio } = member;
  const name = language === ENGLISH ? member.name : member.nameCn;
  const role = language === ENGLISH ? member.role : member.roleCn;

  return (
    <div>
      <div
        key={index}
        class="m-2 sm:mx-6 m-1 sm:my-4 cursor-pointer"
        onClick={() => navigate(`/team/${nickname}`)}
      >
        <div class="photo-container aspect-square overflow-hidden">
          <img
            id={`member_${nickname}`}
            class="w-64 object-cover"
            src={`/media/portraits/${nickname}.jpg`}
            alt={name}
          />
        </div>
        <div class="text-container cursor-pointer py-2 overflow-x-auto mb-5">
          <h3 class="text-2xl tracking-tight flex shrink-0">{name}</h3>
          <span class="text-md flex shrink-0">{role}</span>
        </div>
      </div>
    </div>
  );
}

// Generates all team members
function RenderTeamMembers(members, language) {
  return members.map((member, index) => (
    <TeamMember member={member} language={language} index={index} />
  ));
}

// Main team page content
const TeamContent = () => {
  const [cofounders, setCoFounders] = useState(coFounders);
  const [teammembers, setTeamMembers] = useState(teamMembers);
  const { language } = useContext(AppContext);

  useEffect(() => {
    // if we have two columns and an odd number of cofounders, add first team member to cofounders
    const handleResize = () => {
      setCoFounders(coFounders);
      setTeamMembers(teamMembers);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [teammembers, cofounders]);

  return (
    <div
      id="ourteamsection"
      className="bg-gradient-to-r from-slate-300 via-slate-100 to-slate-300 team-content font-crimson"
    >
      <div className="items-center flex flex-col">
        <div className="flex w-full h-screen items-center justify-center bg-[url('/public/media/group-photos/group-horizontal.jpg')] bg-center bg-cover">
          <text className="text-5xl sm:text-6xl md:text-7xl text-white mt-10">
            {translations[language].ourteam}
          </text>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
          {RenderTeamMembers(cofounders, language)}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {RenderTeamMembers(teammembers, language)}
        </div>
      </div>
    </div>
  );
};

export default TeamContent;
