import React, { useContext, useState } from 'react';
import ALinkHeaderInfo from './AlinkHeaderInfo';
import translations from '../utils/translations';
import { AppContext } from '../AppContext';
import LanguageSelect from './LanguageSelect';
import { Link } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const NavigationBar = () => {
  const { language } = useContext(AppContext);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="page-header bg-[#191c40] bg-opacity-90">
      <div className="flex justify-end">
        <LanguageSelect />
      </div>
      <div className="flex items-center justify-between lg:mx-10 mx-2 pb-2 relative">
        <ALinkHeaderInfo />
        <div className="lg:hidden p-2 md:p-4">
          <button onClick={toggleNav} aria-label="Open Menu">
            {isNavOpen ? (
              <Bars3Icon className="h-7 w-7 text-white" />
            ) : (
              <Bars3Icon className="h-7 w-7 text-white" />
            )}
          </button>
        </div>
        <nav
          className={`${
            isNavOpen ? 'block' : 'hidden'
          } lg:hidden absolute z-10 top-full right-0 bg-[#191c40] bg-opacity-90 mt-2 rounded shadow-lg transition-all`}
        >
          <ul className="leading-5 text-xl text-white font-crimson text-slate-200 flex flex-col space-y-2 p-4">
             {/* {['home', 'ourteam', 'listings', 'contactus'].map((item, index) => ( */}
            {['home', 'ourteam', 'contactus'].map((item, index) => (
              <li
                key={index}
                className="my-2 hover:bg-opacity-70 hover:bg-slate-700 p-2 rounded"
              >
                <Link
                  activeClass="active"
                  to={`${item}section`}
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (item === "listings") {
                      // If the index is for 'listings'
                      navigate('/listings');
                    } else {
                      // Navigate to the homepage
                      navigate('/');
                      // Set a short delay before scrolling to the section
                      setTimeout(() => {
                        const section = document.getElementById(
                          `${item}section`,
                        );
                        section?.scrollIntoView({ behavior: 'smooth' });
                      }, 200); // Adjust the delay as needed
                    }
                  }}
                >
                  {translations[language][item]}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className="hidden lg:block">
          <div className="container lg:flex lg:flex-row lg:justify-end items-center">
            <ul className="leading-5 text-2xl text-white font-crimson text-slate-200 lg:flex lg:gap-x-12">
              {/* Menu Items */}
              {/* {['home', 'ourteam', 'listings', 'contactus'].map( */}
              {['home', 'ourteam', 'contactus'].map(
                (item, index) => (
                  <li key={index} className="lg:my-0 my-4">
                    <Link
                      activeClass="active"
                      to={`${item}section`}
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (item === "listings") {
                          // If the index is for 'listings'
                          navigate('/listings');
                        } else {
                          // Navigate to the homepage
                          navigate('/');
                          // Set a short delay before scrolling to the section
                          setTimeout(() => {
                            const section = document.getElementById(
                              `${item}section`,
                            );
                            section?.scrollIntoView({ behavior: 'smooth' });
                          }, 200); // Adjust the delay as needed
                        }
                      }}
                    >
                      {translations[language][item]}
                    </Link>
                  </li>
                ),
              )}
            </ul>
          </div>
          <ScrollToTop />
        </div>
      </div>
    </header>
  );
};

export default NavigationBar;
