import React, { useState, useContext, useEffect } from 'react';
import NavigationBar from '../NavigationBar';
import { firestore } from '../../firebase';
import { formatDate, convertToDate } from '../../utils/utils';
import { useQuery } from 'react-query';
import { collection, getDocs } from 'firebase/firestore';
import ContactUs from '../ContactUs';
import AddListingButton from './AddListingModal';
import { AppContext } from '../../AppContext';
import DeleteListingButton from './DeleteListingButton';
import EditListingButton from './EditListingModal';

const PAGE_SIZE = 6; // Assuming 3 listings per row and 2 rows per page

// filters for listings
const RENTALS = 'Rentals';
const RECENTLY_CLOSED = 'Recently Closed';
const SALES = 'Sales';

// all possible listing states
const CLOSED = 'Closed';
const FOR_RENT = 'For Rent';
const FOR_SALE = 'For Sale';
const ACTIVE_UNDER_CONTRACT = 'Active Under Contract';
const COMING_SOON = 'Coming Soon';

// fetch the listings from firebase
const fetchListings = async () => {
  const querySnapshot = await getDocs(collection(firestore, 'listings'));
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const ListingsContent = () => {
  // default the filter to 'sales'
  const [filter, setFilter] = useState(SALES);
  const [currentPage, setCurrentPage] = useState(1);
  const { userIsAuthenticated } = useContext(AppContext);

  // run react query to get the listings
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: 'listings',
    queryFn: fetchListings,
    staleTime: Infinity,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  // filter the listings to be displayed
  const filteredData = React.useMemo(() => {
    const now = new Date();
    const oneYearAgo = new Date(now.setFullYear(now.getFullYear() - 1));

    return (
      data
        ?.filter((listing) => {
          // for recently-closed tab, get listings where the state is closed and was closed within the last year
          if (filter === RECENTLY_CLOSED) {
            return (
              listing.State === CLOSED &&
              convertToDate(listing.ClosedDate) >= oneYearAgo
            );
          }

          // for rentals tab, listing should be in for-rent state
          if (filter === RENTALS) {
            return listing.State === FOR_RENT;
          }

          // for sales tab, listing should either be for sale, or active
          return (
            listing.State === FOR_SALE ||
            listing.State === ACTIVE_UNDER_CONTRACT ||
            listing.State === COMING_SOON
          );
        })

        // for recently-closed tab, sort listings by the closed date descending
        .sort((a, b) => {
          if (filter === RECENTLY_CLOSED) {
            return convertToDate(b.ClosedDate) - convertToDate(a.ClosedDate);
          }
          return 0;
        })
    );
  }, [data, filter]);

  // total page count in the result
  const pageCount = Math.ceil(filteredData?.length / PAGE_SIZE);
  const paginatedData = filteredData?.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE,
  );

  const getPaginationGroup = () => {
    let start = Math.max(currentPage - 1, 1);
    let end = Math.min(currentPage + 1, pageCount);

    // Ensure three pages are shown if possible
    if (start === 1) {
      end = Math.min(3, pageCount);
    } else if (end === pageCount) {
      start = Math.max(pageCount - 2, 1);
    }

    let pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (start > 2) {
      pages.unshift('...');
      pages.unshift(1);
    } else if (start === 2) {
      pages.unshift(1);
    }

    if (end < pageCount - 1) {
      pages.push('...');
      pages.push(pageCount);
    } else if (end === pageCount - 1) {
      pages.push(pageCount);
    }

    return pages;
  };

  const paginationGroup = getPaginationGroup();

  // Handlers for navigation buttons
  const goToNextPage = () => {
    setCurrentPage((current) => Math.min(current + 1, pageCount));
  };

  const goToPreviousPage = () => {
    setCurrentPage((current) => Math.max(current - 1, 1));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex space-x-2">
          <div className="bg-regal-blue h-3 w-3 rounded-full animate-pulse"></div>
          <div className="bg-regal-blue h-3 w-3 rounded-full animate-pulse"></div>
          <div className="bg-regal-blue h-3 w-3 rounded-full animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (error) return <div>An error has occurred: {error.message}</div>;

  return (
    <>
      <NavigationBar />

      <div>
        <div className="flex flex-col items-center flex-grow">
          <text className="text-3xl sm:text-4xl md:text-5xl font-crimson mt-10">
            Featured Listings
          </text>
          <text className="text-lg sm:text-xl md:text-2xl font-crimson my-1 text-center mx-4">
            Explore a variety of homes each chosen for their distinct qualities
            and offerings.
          </text>
        </div>

        <div className="relative flex items-center w-full mb-4 mt-4">
  <div className="flex justify-center w-full space-x-4">
    {['Sales', 'Rentals', 'Recently Closed'].map((f) => (
      <button
        key={f}
        onClick={() => setFilter(f)}
        className={`py-2 px-4 rounded ${
          filter === f
            ? 'bg-regal-blue text-white'
            : 'bg-gray-200 text-black'
        }`}
      >
        {f}
      </button>
    ))}
  </div>

  {userIsAuthenticated && (
    <div className="hidden md:block absolute right-10">
      <AddListingButton refetch={refetch} />
    </div>
  )}
</div>


        <div className="gallery grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 m-4 gap-4">
          {paginatedData?.map((listing, index) => (
            <div key={index} className="flex flex-col p-4">
              <a
                href={listing.ZillowLink || '#'}
                onClick={(e) => {
                  if (!listing.ZillowLink) {
                    e.preventDefault(); // Prevent navigation if ZillowLink is empty
                  }
                }}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full h-80 overflow-hidden shadow-lg relative mb-4 block cursor-pointer group"
              >
                <img
                  className="w-full h-full object-cover group-hover:brightness-85 transition duration-300" // Adjust brightness on hover
                  src={listing.ImageUrl}
                  alt={`Listing at ${listing.Address}`}
                />

                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 group-hover:bg-opacity-0 transition duration-300">
                  {' '}
                  {/* Adjust background opacity on hover */}
                  <div className="text-4xl md:text-4xl lg:text-4xl text-white text-center font-crimson overflow-hidden px-2 group-hover:hidden">
                    {' '}
                    {/* Hide text on hover */}
                    {listing.Address}, {listing.City}
                  </div>
                </div>

                {/* Black rectangle in the top right corner */}
                <div className="absolute top-0 right-0 bg-black text-white p-2 m-3 font-raleway">
                  <div className="text-sm">
                    {listing.State}{' '}
                    {filter === RECENTLY_CLOSED
                      ? ' - ' + formatDate(convertToDate(listing.ClosedDate))
                      : ''}{' '}
                  </div>
                </div>

                {userIsAuthenticated && (
                  <div>
                    {/* Delete (X mark) button */}
                    <DeleteListingButton
                      listingId={listing.id}
                      refetch={refetch}
                    />
                    {/* Edit (pencil) button */}
                    <EditListingButton listing={listing} refetch={refetch} />
                  </div>
                )}
              </a>

              <div className="text-xl md:text-2xl lg:text-3xl text-black font-raleway overflow-hidden ml-1">
                $
                {filter === RECENTLY_CLOSED
                  ? listing.ClosingPrice.toLocaleString()
                  : listing.ListingPrice.toLocaleString()}
              </div>
              <div className="text-md md:text-lg lg:text-xl text-black font-jost overflow-hidden ml-1">
                {listing.NumBed} beds | {listing.NumBath} baths |{' '}
                {listing.SqFt.toLocaleString()} sq.ft.
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center space-x-4 mb-8">
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className="text-regal-blue py-2 px-4 rounded"
          >
            {'<'}
          </button>
          {paginationGroup.map((page) =>
            page === '...' ? (
              <span key={page} className="py-2 px-4">
                ...
              </span>
            ) : (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`py-2 px-4 rounded ${
                  currentPage === page
                    ? 'bg-regal-blue text-white'
                    : 'border border-regal-blue text-regal-blue'
                }`}
              >
                {page}
              </button>
            ),
          )}
          <button
            onClick={goToNextPage}
            disabled={currentPage === pageCount}
            className="text-regal-blue py-2 px-4 rounded"
          >
            {'>'}
          </button>
        </div>

        <ContactUs />
      </div>
    </>
  );
};

export default ListingsContent;
